import { configureStore } from "@reduxjs/toolkit";
import { api } from "./services/baseApiSetup";
import observabilitySlice from "../features/observability/observabilitySlice";
import commonSlice from "./commonSlice";
import efficiencySlice from "../features/efficiency/efficiencySlice";
import configurationSlice from "../features/configuration/configurationSlice";
import HomeSlice from "../features/homePage/HomeSlice.js";
import CMDBSlice from "../features/configuration/cmdb/CMDBSlice.js";
import CostSlice from "../features/gnc/CloudCostInsights/CostSlice";
import CBudgetSlice from "../features/configuration/cmdb/CloudBudget/CBudgetSlice";
import GoLiveSlice from "../features/configuration/cmdb/GoLiveAlertConfigutation/GoLiveSlice.js";
import AORBudgetSlice from "../features/configuration/cmdb/AORBudget/AORBudgetSlice.js";
import BudgetSlice from "../features/configuration/cmdb/BudgetDetails/BudgetSlice.js";
import SecurityHubSlice from "../features/gnc/complianceCheck/securityHub/SecurityHubSlice";
 import PIIConfigurationSlice from "../features/configuration/settings/PIIConfiguration/PIIConfigurationSlice";
 import appConfigSlice from "../features/configuration/cmdb/appConfig/appConfigSlice.js";
import EmailScheduleConfigSlice from "../features/configuration/settings/Schedulers/EmailScheduleConfiguration/EmailScheduleSlice";
import HomeApiSlice from "../features/homePage/HomeApiSlice";
import LicenseSlice from "../features/gnc/LicenseManagement/LicenseSlice";
import AuditLogSlice from "../features/efficiency/auditLog/AuditLogSlice.js";
import ToolsSlice from "../features/configuration/settings/tools/ToolsSlice.js";
// import GroupSlice from "../features/configuration/settings/groups/GroupSlice.js";
import OrgInfoSlice from "../features/configuration/cmdb/Organizational-Info/OrgInfoSlice.js";
import DataImportConfigSlice from "../features/configuration/settings/Schedulers/DataImportScheduler/DataImportSlice";
import  filterSlice from "./common-components/CommonFilter/complainceFilterSlice.js";
import vulnerabilityApi from "../features/gnc/cisoDashboard/FilterDetailsSlice";
import CloudCostConfigSlice from "../features/configuration/settings/CloudCostConfig/CloudCostConfigSlice.js";
import BusinessInfoSlice from "../features/configuration/cmdb/BusinessInfo/BusinessInfoSlice.js";
import CloudScapeSlice from "../features/gnc/complianceCheck/CloudScape/CloudScapeSlice.js"
import CisoCloudScapeSlice from "../features/gnc/cisoDashboard/CisoCloudScapeSlice.js";
import InventorySlice from "../features/configuration/cmdb/SoftwareInventory/InventorySlice.js";
import SoftwareGroupSlice from "../features/configuration/cmdb/SoftwareGroup/SoftwareGroupSlice.js";
import  EntitlementSlice  from "../features/gnc/LicenseManagement/LicenseEntitleMent/EntitlementSlice.js";
import DevicesSlice from "../features/configuration/cmdb/MasterDevices/DevicesSlice.js";

// import { productivityMetricsSlice } from "../features/observability/ProductivityMetricsObs/ProductivityMetricsSlice";
 export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    observability: observabilitySlice,
    common: commonSlice,
    efficiency:efficiencySlice,
    configuration: configurationSlice, 
    Homedata:HomeSlice,
    cmdbId:CMDBSlice,
    cloudCostApi:CostSlice,
    CBudgetApi:CBudgetSlice,
    GoLiveApi:GoLiveSlice,
    AORBudgetApi:AORBudgetSlice,
    BudgetApi:BudgetSlice,
    SecurityHubApi:SecurityHubSlice,
    CloudScapeApi:CloudScapeSlice,
    CisoCloudScapeSlice:CisoCloudScapeSlice,
    PIIConfigurationSlice:PIIConfigurationSlice,
    appConfig:appConfigSlice,
    InventoryConfig:InventorySlice,
    DevicesConfig:DevicesSlice,
    EntitlementConfig:EntitlementSlice,
    EmailSchduleConfiguration:EmailScheduleConfigSlice,
    DataImportConfig:DataImportConfigSlice,
    HomeApiCall:HomeApiSlice,
    LicenseApiCall : LicenseSlice,
    SoftwareGroupSlice: SoftwareGroupSlice,
    AuditLogApi: AuditLogSlice,
    toolsApi : ToolsSlice,
    CloudCostConfigApi:CloudCostConfigSlice,
    BusinessInfoConfig:BusinessInfoSlice,
    // GroupApi : GroupSlice,
    OrganizationInformation : OrgInfoSlice,
    filterApi : filterSlice,
    vulnerabilityApi: vulnerabilityApi
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(api.middleware),
});
