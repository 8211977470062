import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getTechnologyMasterList = createAsyncThunk(
    "post/getTechnologyMasterList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getTechnologyMasterList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const getTechnologyMasterListCount = createAsyncThunk(
    "post/getTechnologyMasterListCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const {payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getTechnologyMasterListCount`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const getServerOSMasterList = createAsyncThunk(
    "post/getServerOSMasterList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getOperatingSystemMasterList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const getServerOSMasterListCount = createAsyncThunk(
    "post/getServerOSMasterListCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const {payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getOperatingSystemMasterListCount`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const getEsxMasterList = createAsyncThunk(
    "post/getEsxMasterList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getEsxMasterList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const getEsxMasterListCount = createAsyncThunk(
    "post/getEsxMasterListCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const {payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getEsxMasterListCount`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

const initialState = {
    TechnologyMasterListData: [],
    TechnologyMasterListLoading: false,
    TechnologyMasterCountData:[],
    TechnologyMasterCountLoading:false,
    ServerOSMasterListData:[],
    ServerOSMasterListLoading:false,
    getServerOSMasterListCountData:[],
    getServerOSMasterListCountLoading:false,
    getEsxMasterListData:[],
    getEsxMasterListLoading:false,
    getEsxMasterListCountData:[],
    getEsxMasterListCountLoading:false,
    error: "",
    apiError: false,
};

export const DevicesSlice = createSlice({
    name: 'MasterDevicesSlice',
    initialState,


    reducers: {
        resetCostSliceData: () => {
            return initialState;
        },

    },
    extraReducers: (builder) => {
        builder
            //getTechnologyMasterList
            .addCase(getTechnologyMasterList.pending, (state, action) => {
                state.TechnologyMasterListLoading = true;
                state.apiError = false;
            })
            .addCase(getTechnologyMasterList.fulfilled, (state, action) => {
                state.TechnologyMasterListData = action.payload;
                state.TechnologyMasterListLoading = false;
                state.apiError = false;
            })
            .addCase(getTechnologyMasterList.rejected, (state, action) => {
                state.TechnologyMasterListLoading = false;
                state.TechnologyMasterListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //getTechnologyMasterListCount
            .addCase(getTechnologyMasterListCount.pending, (state, action) => {
                state.TechnologyMasterCountLoading = true;
                state.apiError = false;
            })
            .addCase(getTechnologyMasterListCount.fulfilled, (state, action) => {
                state.TechnologyMasterCountData = action.payload;
                state.TechnologyMasterCountLoading = false;
                state.apiError = false;
            })
            .addCase(getTechnologyMasterListCount.rejected, (state, action) => {
                state.TechnologyMasterCountLoading = false;
                state.TechnologyMasterCountData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //getServerOSMasterList
            .addCase(getServerOSMasterList.pending, (state, action) => {
                state.ServerOSMasterListLoading = true;
                state.apiError = false;
            })
            .addCase(getServerOSMasterList.fulfilled, (state, action) => {
                state.ServerOSMasterListData = action.payload;
                state.ServerOSMasterListLoading = false;
                state.apiError = false;
            })
            .addCase(getServerOSMasterList.rejected, (state, action) => {
                state.ServerOSMasterListLoading = false;
                state.ServerOSMasterListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            
            //getServerOSMasterListCount
            .addCase(getServerOSMasterListCount.pending, (state, action) => {
                state.getServerOSMasterListCountLoading = true;
                state.apiError = false;
            })
            .addCase(getServerOSMasterListCount.fulfilled, (state, action) => {
                state.getServerOSMasterListCountData = action.payload;
                state.getServerOSMasterListCountLoading = false;
                state.apiError = false;
            })
            .addCase(getServerOSMasterListCount.rejected, (state, action) => {
                state.getServerOSMasterListCountLoading = false;
                state.getServerOSMasterListCountData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //getEsxMasterList
            .addCase(getEsxMasterList.pending, (state, action) => {
                state.getEsxMasterListLoading = true;
                state.apiError = false;
            })
            .addCase(getEsxMasterList.fulfilled, (state, action) => {
                state.getEsxMasterListData = action.payload;
                state.getEsxMasterListLoading = false;
                state.apiError = false;
            })
            .addCase(getEsxMasterList.rejected, (state, action) => {
                state.getEsxMasterListData = false;
                state.getServerOSMasterListCountData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //getEsxMasterListCount
            .addCase(getEsxMasterListCount.pending, (state, action) => {
                state.getEsxMasterListCountLoading = true;
                state.apiError = false;
            })
            .addCase(getEsxMasterListCount.fulfilled, (state, action) => {
                state.getEsxMasterListCountData = action.payload;
                state.getEsxMasterListCountLoading = false;
                state.apiError = false;
            })
            .addCase(getEsxMasterListCount.rejected, (state, action) => {
                state.getEsxMasterListCountLoading = false;
                state.getEsxMasterListCountData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            });

    },

});

export const { resetCostSliceData } = DevicesSlice.actions;
export default DevicesSlice.reducer;

