import React, { useState, useEffect } from "react";
import {
  A360,
  A360_Content,
  MAS_Sec_Logo,
  user,
  logout,
  Logout,
  user15,
} from "../../assets/images/index";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { pageTitleMapper, getPageTitle } from "../utilities/constants";
import { get, isEmpty } from "lodash";
import { routes } from "../routes";
import _, { mapValues } from "lodash";
// import { UserRoleForNavBar } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { storageRemove } from "./Storage";
import BreadCrumb from "./BreadCrumb";
import Cookies from "universal-cookie";
import {setNavigationClicked} from "../commonSlice";
import GetProxy from "../../features/common/GetProxy";
import { resetLoggedUser, setPageLoading,setLoggedUser, setActiveUrl } from "../commonSlice";
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { setsessionToken } from "../commonSlice";
import AlertBox from "./Alert";
import { updateSelectedApp } from "../../features/homePage/HomeSlice";
import { useCommonAlert } from "../../features/common/helpers";
import { hasAccessToPage, navigationPanelConfig } from "../../features/common/RoleConfig";
import NotificationOffCanvas from "./NotificationOffCanvas";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import { decrypt } from "../utilities/helpers";

let client;
const proxy = GetProxy();
const Container = () => {
  const [showNotificationDD, setShowNotificationDD] = useState(false);
  const [notificationData, setNotifications] = useState([]);
  const { hideCommonAlert } = useCommonAlert();
  const [childMenuState, setChildMenuState] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();

  const [tabClick, setTabClick] = useState({});
  const cookies = new Cookies();
  const { userName, userRole, displayName, showLM } = useSelector(
    (state) => state.common.loggedUser
  ); 
  const encoded = decrypt(userName)
  const { openAlert, alertType ,alertMessage} = useSelector(
    (state) => state.common.commonAlert
  );

  // UserRoleForNavBar(userRole)
  const navigate = useNavigate();
  // const pathName = get(location, "pathname", "");
  const pathName =
    get(location, "pathname", "") === "/gnc/ciso-view/Vulnerability" &&
    userRole[0] !== "Ciso"
      ? ""
      : get(location, "pathname", "");

  const onNavigate = (e, url) => {
    e.preventDefault();
    dispatch(setPageLoading(true));
    navigate(url);
  };
  const logOut = (e) => {
    const token = cookies.get("apitoken");
    const User = cookies.get("username");
    e.preventDefault();
    var newURL =
      pathName === "/gnc/ciso-view/Vulnerability"
        ? "/gnc/Vulnerability"
        : pathName;
    sessionStorage.setItem("lastVisitedURL", newURL);
    sessionStorage.setItem("lastVistedURL", newURL);
    sessionStorage.setItem("isLoggedin", false);
    fetch(`${proxy}/auth?logout`, {
      method: "GET",
      redirect: "manual",
      headers: {
        "CMTS-API-TOKEN":token,
        "USERNAME":User,
      },
    }).then((res) => {
        if (client && client.connected) {
          client.deactivate();
        }
        handleLogout();
        navigate("/auth");
        dispatch(resetLoggedUser());
        dispatch(resetHomeData());
        dispatch(setsessionToken(false));
      })
      .catch((err) => {});
  };

  const handleLogout = () => {
    cookies.remove("jwttoken", { path: "/"});
    cookies.remove("Refersh", { path: "/"});
    localStorage.removeItem("apitokens");
    localStorage.removeItem("username");
    localStorage.removeItem("JSESSIONID");
    cookies.remove("apitoken", { path: "/"});
    cookies.remove("username", { path: "/"});
    cookies.remove("referstoken", { path: "/"});
    localStorage.removeItem("myapp-username");
    localStorage.removeItem("myapp-password")
    dispatch(setLoggedUser({ userName: "", userRole: [], token: "" , showLM: ''}));
    // dispatch(setPageLoading(true));
    navigate("/auth"); 
  };

  const handleChildMenuState = (path, value) => {
    setChildMenuState((childMenuState) => {
      const resetMenuState = mapValues({ ...childMenuState }, () => false);
      return { ...resetMenuState, [path]: value };
    });
  };
  
  const handleArrowNavigation = (event) => {
    const submenuOpen = document.querySelector(".nav.small");
    if (submenuOpen) {
      const submenuLinks = submenuOpen.querySelectorAll('[tabIndex="0"]');
      const currentLinkIndex = Array.from(submenuLinks).indexOf(document.activeElement);
      if (event.key === "ArrowUp") {
        event.preventDefault();
        const nextLinkIndex = currentLinkIndex === 0 ? submenuLinks.length - 1 : currentLinkIndex - 1;
        submenuLinks[nextLinkIndex].focus();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        const nextLinkIndex = (currentLinkIndex + 1) % submenuLinks.length;
        submenuLinks[nextLinkIndex].focus();
      }
    }
  };


  useEffect(() => {
    if(!isEmpty(tabClick)){
    document.addEventListener("keydown", handleArrowNavigation);
    return () => {
      document.removeEventListener("keydown", handleArrowNavigation);
    };
  }
  }, [tabClick]);


 const [lastFocusByMouse, setLastFocusByMouse] = useState(false);
 const [lastTabAccess, setLastTabAccess] = useState(false);

  const handleDocumentEvent = (event) => {
    if (event.type === 'click') {
      setLastFocusByMouse(true);
      setLastTabAccess(false)
    } else if (event.type === 'keydown' && event.key === 'Tab') {
      setLastFocusByMouse(false);
      setLastTabAccess(true);
    }
  };

  const handleFocusIn = (input, lastFocusByMouse,lastTabAccess) => {
    // Remove classes from all inputs
    document.querySelectorAll('input, textarea, button,select').forEach((el) => {
      el.classList.remove('tabClickStyle', 'mouseClickStyle');
    });

    // Add class to the current input
    if (lastFocusByMouse) {
      input.classList.add('mouseClickStyle');
    } else if(lastTabAccess){
      input.classList.add('tabClickStyle');
    }
  };

  const handleFocusOut = (input) => {
    // Remove classes from the input
    input.classList.remove('tabClickStyle', 'mouseClickStyle');
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentEvent);
    document.addEventListener('keydown', handleDocumentEvent);

    return () => {
      document.removeEventListener('click', handleDocumentEvent);
      document.removeEventListener('keydown', handleDocumentEvent);
    };
  }, []);
  useEffect(() =>{
    hideCommonAlert(0);
  },[location])

  useEffect(() => {
    const focusInHandler = (event) => handleFocusIn(event.target, lastFocusByMouse,lastTabAccess);
    const focusOutHandler = (event) => handleFocusOut(event.target);

    document.querySelectorAll('input, textarea, button, select').forEach((input) => {
      input.addEventListener('focusin', focusInHandler);
      input.addEventListener('focusout', focusOutHandler);
      input.addEventListener('mousemove', focusOutHandler);
    });

    return () => {
      document.querySelectorAll('input, textarea, button, select').forEach((input) => {
        input.removeEventListener('focusin', focusInHandler);
        input.removeEventListener('focusout', focusOutHandler);
        input.addEventListener('mousemove', focusOutHandler);
      });
    };
  }, [lastFocusByMouse]);

  useEffect(() => {
    client = new Client({
      brokerURL: `${proxy}/websocket`,

      webSocketFactory: () => new SockJS(`${proxy}/websocket`),

      onConnect: () => {

        client.subscribe("/topic/notifications", (message) => {
          // console.log("Received message:", message.body);

          const parsedMessage = JSON.parse(message.body);

          if (
            parsedMessage.userRoles.includes(userRole[0].toUpperCase()) &&
            parsedMessage.userNames.includes(encoded)
          ) {
            setNotifications(parsedMessage);
          } else {
            setNotifications([]);
          }
        });
      },

      onStompError: (frame) => {
        console.error("WebSocket error:", frame);
      },
    });
    client.activate();
  }, [proxy]);

  const [arrayOfnavCount, setarrayOfnavCount] = useState();
  const getNavCount = (basePath) => {
    const navCount = [];
    routes.forEach((route) => {
      route.childNavs.forEach((firstChildNav) => {
        if (!firstChildNav.isNotSideBarNavigation) {
          if (route.name === basePath) {
            if(hasAccessToPage(
              firstChildNav.name,
              userRole,
              navigationPanelConfig
            )){
              navCount.push(firstChildNav.name);
            }
          }
        }
      });
    });
    setarrayOfnavCount(navCount.length); // Set state after collecting all names
  };
  const userRoleShow = () => {
    if (userRole && userRole.length !== 0) {
      const userRoleShow = userRole.map(item => item === 'Ciso' ? 'CISO' :item === 'Soc Lead' ?'SOC Lead': item);
      if (userRoleShow) {
        return (
          <>
            <a className="">
              {userRoleShow[0]}
            </a>
          </>
        );
      }
    }
    return null; // Return null if no userRole exists or it's empty
  };

  return (
    <>
      <div className="wrapper_jr">
        {showNotificationDD && (
          <NotificationOffCanvas
            onClose={() => {
              setShowNotificationDD(false);
            }}
            onclear={() => {
              handlenotificationclear();
            }}
            notificationData={notificationData}
          />
        )}
        <header
          onMouseLeave={() => {
            setTabClick({});
          }}
        >
          <div className="headerwrap">
            <div className="header-logo">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="logoTooltip">
                    {/* <img className="logoTooltipImg" src={A360_Content} alt="Advisor 360" /> */}
                    <a>Monetary Authority of Singapore</a>
                  </Tooltip>
                }
              >
                <a tabIndex={0}>
                  <img src={MAS_Sec_Logo} alt="MAS" />
                </a>
              </OverlayTrigger>
            </div>
            <div className="nav small">
              <ul>
                {routes.map((route, index) => {
                  if (
                    !hasAccessToPage(
                      route.path,
                      userRole,
                      navigationPanelConfig
                    ) || (route.name === "Software License Management" && !showLM)
                  ) {
                    return null;
                  }
                  return (
                    <li
                      className={`dropdown-btn ${
                        pathName.includes(route?.path?.split("/")[0]) &&
                        "active"
                      } ${tabClick.index === index ? "tabInde" : ""} `}
                    >
                      <a
                        tabIndex={0}
                        className={`navIcon ${route?.iconClass}`}
                        // href={
                        //   route.iconNavigation ? `/${route.path}` : undefined
                        // }
                        // onClick={(e) => {
                        //   route.iconNavigation && onNavigate(e, route.path);
                        //   if (route.path === "home") {
                        //     dispatch(setNavigationClicked(true));
                        //     dispatch(updateSelectedApp({}));
                        //   }

                        //   setTabClick({});
                        // }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            route.iconNavigation && onNavigate(e, route.path);

                            setTabClick({ index: index });
                          }
                        }}
                        // className={`${
                        //   route?.toolTipCustomClass
                        //     ? route?.toolTipCustomClass
                        //     : "hovertip"
                        // }`}
                        // data-tip={route.name}
                      >
                        <img src={route.icon} alt={route.name} />
                      </a>
                      <div 
                      className={`dropdown-container `}
                      >
                      {route?.childNavs?.length > 0 &&
                        route.name !== "Compliance" &&(
                          <>
                            <strong className={`firstChildNav-heading ${route.name === "Home" ? "noborder" : ""}`}>
                            {route.path === "home"?(
                              <a
                              tabIndex={0}
                              className="navIcon"
                              href={
                                route.iconNavigation ? `/${route.path}` : undefined
                              }
                              onClick={(e) => {
                                route.iconNavigation && onNavigate(e, route.path);
                                if (route.path === "home") {
                                  dispatch(setNavigationClicked(true));
                                  dispatch(updateSelectedApp({}));
                                }

                                setTabClick({});
                              }}
                              >
                                {route.name}
                              </a>
                            ):(<>{route.name}</>)}
                            
                              
                            </strong>

                            {/* <hr className="firstChildNav-heading-hr" /> */}
                          </>
                        )}
                        {route?.childNavs?.map(
                          (firstChildNav, firstChildNavIndex) => {
                            // Add this line to log the object
                            if (
                              !hasAccessToPage(
                                firstChildNav.name,
                                userRole,
                                navigationPanelConfig
                              )|| (!showLM && firstChildNav.name === "License Management")
                            ) {
                              return null; // return null to skip rendering this component
                            }

                            return (
                              !firstChildNav.isNotSideBarNavigation && (
                                <>
                                  <a
                                    tabIndex={tabClick.index === index ? 0 : 1}
                                    href={
                                      !firstChildNav.navigationDisable
                                        ? `/${firstChildNav?.path}`
                                        : undefined
                                    }
                                    onClick={(e) => {
                                      !firstChildNav.navigationDisable &&
                                        onNavigate(e, firstChildNav?.path),
                                        storageRemove(),
                                        dispatch(updateSelectedApp({}));
                                    }}
                                    onKeyDown={(e) => {
                                      // console.log(e);
                                      if (e.keyCode === 13) {
                                        route.iconNavigation &&
                                          onNavigate(e, route.path);
                                      } else if (e.keyCode === 39) {
                                        setTabClick({
                                          index: index,
                                          firstChInd: firstChildNavIndex,
                                        });
                                        handleChildMenuState(
                                          firstChildNav.path,
                                          true
                                        );
                                      } else if (
                                        e.keyCode === 37 ||
                                        e.keyCode === 27
                                      ) {
                                        setTabClick({
                                          index: index,
                                          firstChInd: firstChildNavIndex,
                                        });
                                        handleChildMenuState(
                                          firstChildNav.path,
                                          false
                                        );
                                      }
                                    }}
                                    onMouseOver={() => {
                                      handleChildMenuState(
                                        firstChildNav.path,
                                        true
                                      ),
                                      getNavCount(route.name);
                                        setTabClick({});
                                    }}
                                    onMouseLeave={() => {
                                      handleChildMenuState(
                                        firstChildNav.path,
                                        false
                                      ),
                                        setTabClick({});
                                    }}
                                    className={
                                      firstChildNav.navigationDisableNotAllowed
                                        ? "not-allowed"
                                        : ""
                                    }
                                  >
                                    {firstChildNav.text}
                                  </a>
                                  {/* Second Level childNavs */}
                                  {firstChildNav?.childNavs &&
                                    firstChildNav.name !== "Compliance" && (
                                      <div
                                        className={`dropdown-container_child custom_scroll_Common custom_scroll_Common ${
                                          firstChildNav.customDropContainerClass &&
                                          firstChildNav.customDropContainerClass
                                        } ${
                                          childMenuState[firstChildNav.path] &&
                                          "active"
                                        } ${`index${arrayOfnavCount}`}`}
                                        onMouseOver={() =>
                                          handleChildMenuState(
                                            firstChildNav.path,
                                            true
                                          )
                                        }
                                        onMouseLeave={() =>
                                          handleChildMenuState(
                                            firstChildNav.path,
                                            false
                                          )
                                        }
                                      >
                                        <strong className="firstChildNav-heading"
                                        style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                                          {firstChildNav.name}{" "}
                                          {/* Second child heading */}
                                        </strong>
                                        {/* <hr className="firstChildNav-heading-hr" /> */}
                                        {firstChildNav?.childNavs?.map(
                                          (
                                            secondChildNav,
                                            secondChildNavIndex
                                          ) => {
                                            if (
                                              !hasAccessToPage(
                                                secondChildNav.name,
                                                userRole,
                                                navigationPanelConfig
                                              )|| (!showLM && secondChildNav.name === "Devices")
                                            ) {
                                              return null; // return null to skip rendering this component
                                            }
                                            return (
                                              !secondChildNav.isNotSideBarNavigation && (
                                                <>
                                                  <a
                                                    tabIndex={
                                                      childMenuState[
                                                        firstChildNav.path
                                                      ] && "active"
                                                        ? 0
                                                        : 1
                                                    }
                                                    key={secondChildNav.path}
                                                    href={
                                                      !secondChildNav.navigationDisable
                                                        ? `/${secondChildNav?.path}`
                                                        : undefined
                                                    }
                                                    onClick={(e) => {
                                                      !secondChildNav.navigationDisable &&
                                                        onNavigate(
                                                          e,
                                                          secondChildNav?.path
                                                        ),
                                                        storageRemove(),
                                                        setTabClick({}),
                                                        dispatch(
                                                          updateSelectedApp({})
                                                        );
                                                    }}
                                                    className={
                                                      secondChildNav.navigationDisableNotAllowed
                                                        ? "not-allowed"
                                                        : ""
                                                    }
                                                  >
                                                    {secondChildNav.text}
                                                  </a>

                                                  {
                                                    <div
                                                      className={`dropdown-container_childthird custom_scroll_Common custom_scroll_Common ${
                                                        secondChildNav.customDropContainerClass &&
                                                        secondChildNav.customDropContainerClass
                                                      } ${
                                                        childMenuState[
                                                          secondChildNav.path
                                                        ] && "active"
                                                      }`}
                                                      onMouseOver={() => {
                                                        handleChildMenuState(
                                                          secondChildNav.path,
                                                          true
                                                        ),
                                                          setTabClick({});
                                                      }}
                                                      onMouseLeave={() => {
                                                        handleChildMenuState(
                                                          secondChildNav.path,
                                                          false
                                                        ),
                                                          setTabClick({});
                                                      }}
                                                    >
                                                      {secondChildNav?.childNavs?.map(
                                                        (thirdChildNav) => {
                                                          if (
                                                            !hasAccessToPage(
                                                              secondChildNav.name,
                                                              userRole,
                                                              navigationPanelConfig
                                                            )
                                                          ) {
                                                            return null; // return null to skip rendering this component
                                                          }

                                                          return (
                                                            <a
                                                              key={
                                                                thirdChildNav.path
                                                              }
                                                              href={`/${thirdChildNav.path}`}
                                                              onClick={(e) => {
                                                                onNavigate(
                                                                  e,
                                                                  thirdChildNav.path
                                                                ),
                                                                  storageRemove(),
                                                                  setTabClick(
                                                                    {}
                                                                  ),
                                                                  dispatch(
                                                                    updateSelectedApp(
                                                                      {}
                                                                    )
                                                                  );
                                                              }}
                                                            >
                                                              {
                                                                thirdChildNav.text
                                                              }
                                                            </a>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  }
                                                </>
                                              )
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                </>
                              )
                            );
                          }
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </header>

        <div className="mainwrap content">
          <div className="topwrap">
            {pathName !== "/access-denied" && (
              <div className="top_Header">
                <div className="topheader">
                  <div className="topheader-left">
                    {/* <h3 id="topheader_text">
                    {getPageTitle(pathName, routes)}
                    </h3> */}
                    <BreadCrumb path={pathName} />
                    {/* {get(pageTitleMapper, `${pathName}.module`, "")}{" "}
                  </h3>
                  <h3 id="Page_header">
                    {get(pageTitleMapper, `${pathName}.pageTitle`, "")}
                  </h3> */}
                  </div>
                  <div className="topheader-right">
                    <div className="notify-block">
                      <span className="notify-bell">
                        <IoIosNotificationsOutline
                          onClick={() => {
                            setShowNotificationDD(true);
                          }}
                        />
                        <span className="notify-ball">
                          {!isEmpty(notificationData) ? (
                            <FaCircle
                              onClick={() => {
                                setShowNotificationDD(true);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </span>
                    </div>
                    <div className="userwrap">
                      <div className="logindes">
                        <h4
                          className={encoded === "sysadmin" ? "usernamedecoded" : ""}
                        >
                          <a className="">{displayName}</a>
                        </h4>
                        <h6>
                          {encoded !== "sysadmin" ? (
                            userRoleShow()
                          ) : null}
                        </h6>
                      </div>
                      <div className="loginico">
                        <a>
                          <img src={user15} />
                        </a>
                      </div>
                    </div>
                    <div className="logout" title-new="Logout">
                      <a onClick={(e) => logOut(e)}>
                        <img src={Logout} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="wrapper"
              style={pathName === "/access-denied" ? { padding: 0 } : {}}
            >
              <Outlet />
            </div>
          </div>
        </div>
        {/* <footer className="footer">
        Powered by Code8
      </footer> */}
      </div>
      <AlertBox alert={openAlert} type={alertType} message={alertMessage} />
    </>
  );
};

export default Container;