import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../../app/utilities/helpers";
import GetProxy from "../../../common/GetProxy";
const proxy = GetProxy();
const cookies = new Cookies();

export const getSoftwareEntitlementList = createAsyncThunk(
    "post/getSoftwareEntitlementList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/getSoftwareEntitlementList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const getByGroupNameAPI = createAsyncThunk(
    "post/getByGroupNameAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { groupName } = props;
        const res = await fetch(
            `${proxy}/api/v1/softwareEntitlement/getByGroupName/${groupName}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);

export const saveEntitlementAPI = createAsyncThunk(
    "post/saveEntitlementAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { userName, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement//saveEntitlementDetails/${userName}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});
export const getSoftwareGroupDivisionCategory = createAsyncThunk("get/getSoftwareGroupDivisionCategory", async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
        const response = await fetch(`${proxy}/api/v1/softwareGroup/getOwnersAndDivisions`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "CMTS-API-TOKEN": apiToken,
                USERNAME: username,
            },
        });
        handleResponseCode(response);
        const data = await response.json();
        return response.ok ? data : Promise.reject(data)
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}
);
export const getInfoEntitleListAPI = createAsyncThunk(
    "post/getInfoEntitleListAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/getSoftwareEntitlementInfoList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST', 
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});

export const getInfoAttestedListAPI = createAsyncThunk(
    "post/getInfoAttestedListAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { id , pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/getAttestationInfoList/${id}/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});

export const getAttestationInfoCountAPI = createAsyncThunk(
    "post/getAttestationInfoCountAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { id , pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/getAttestationInfoListCount/${id}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});

export const getInfoEntitleListConsumedLicensesInfo = createAsyncThunk(
    "post/getInfoEntitleListConsumedLicensesInfo",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload, rowData } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/getConsumedSoftwareList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? { rowData: rowData, data: data } : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});
export const getInfoEntitleListConsumedLicensesInfoCount = createAsyncThunk(
    "post/getInfoEntitleListConsumedLicensesInfoCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload, rowData } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/getConsumedSoftwareListCount`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? { rowData: rowData, data: data } : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});
export const saveNumberOfLicenseConsumed = createAsyncThunk(
    "post/saveNumberOfLicenseConsumed",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareEntitlement/saveNumberOfLicenseConsumed`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});

export const getByAttestActionAPI = createAsyncThunk(
    "post/getByAttestActionAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { groupName } = props;
        const res = await fetch(
            `${proxy}/api/v1/LicenseAttestation/getByGroupName/${groupName}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);

export const getByValidateActionAPI = createAsyncThunk(
    "post/getByValidateActionAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { id } = props;
        const res = await fetch(
            `${proxy}/api/v1/LicenseAttestation/getById/${id}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);

export const saveAttestationActionAPI = createAsyncThunk(
    "post/saveAttestationActionAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/LicenseAttestation/saveAttestationData`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});

export const getConfirmValidationAPI = createAsyncThunk(
    "post/getConfirmValidationAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { id , Username } = props;
        const res = await fetch(
            `${proxy}/api/v1/LicenseAttestation/findByIdAndUpdateLicenseCount/${id}/${Username}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);
  

export const getConsumedCountForValidation = createAsyncThunk(
    "post/getConsumedCountForValidation",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { groupName } = props;
        const res = await fetch(
            `${proxy}/api/v1/softwareEntitlement/getConsumedCountForValidation/${groupName}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);


export const getSoftwareIncludesAPI = createAsyncThunk(
    "post/getSoftwareIncludesAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { rowDataid } = props;
        const res = await fetch(
            `${proxy}/api/v1/softwareGroup/fetchDataByEntitlementId/${rowDataid}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);


export const getGroupNameListByUserName = createAsyncThunk(
    "post/getGroupNameListByUserName",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { UserName } = props;
        const res = await fetch(
            `${proxy}/api/v1/softwareGroup/getGroupNameListByUserName/${UserName}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);

const initialState = {
    SoftwareEntitlementListListData: [],
    SoftwareEntitlementListLoading: false,
    getByGroupNameAPIListData: [],
    getByGroupNameAPILoading: false,
    saveEntitlementAPIData:[],
    saveEntitlementAPILoading:false,
    error: "",
    apiError: false,
    getSoftwareGroupDivisionCategoryData: [],
    getSoftwareGroupDivisionCategoryDataLoading: false,
    getInfoEntitleListData: [],
    getInfoEntitleListLoading: false,
    getInfoAttestedListData: [],
    getInfoAttestedListLoading: false,
    getInfoEntitleListConsumedLicensesInfoData: [],
    getInfoEntitleListConsumedLicensesInfoLoading: false,
    getInfoEntitleListConsumedLicensesInfoCountData: [],
    getInfoEntitleListConsumedLicensesInfoCountLoading: false,
    saveNumberOfLicenseConsumedData:[],
    saveNumberOfLicenseConsumedLoading:false,
    getByAttestActionData:[],
    getByAttestActionLoading:false,
    saveAttestationActionData:[],
    saveAttestationActionLoading:false,
    getByValidateActionData:[],
    getByValidateActionLoading:false,
    getAttestationInfoCountData:[],
    getAttestationInfoCountLoading:false,
    getConsumedCountForValidationData:[],
    getConsumedCountForValidationLoading:false,
    getGroupNameListByUserNameData:[],
    getGroupNameListByUserNameLoading:false,
};

export const EntitlementSlice = createSlice({
    name: 'SoftwareEntitlementSlice',
    initialState,


    reducers: {
        resetCostSliceData: () => {
            return initialState;
        },

    },
    extraReducers: (builder) => {
        builder
            //getSoftwareEntitlementList
            .addCase(getSoftwareEntitlementList.pending, (state, action) => {
                state.SoftwareEntitlementListLoading = true;
                state.apiError = false;
            })
            .addCase(getSoftwareEntitlementList.fulfilled, (state, action) => {
                state.SoftwareEntitlementListListData = action.payload;
                state.SoftwareEntitlementListLoading = false;
                state.apiError = false;
            })
            .addCase(getSoftwareEntitlementList.rejected, (state, action) => {
                state.SoftwareEntitlementListLoading = false;
                state.SoftwareEntitlementListListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //getSoftwareEntitlementList
            .addCase(getByGroupNameAPI.pending, (state, action) => {
                state.getByGroupNameAPILoading = true;
                state.apiError = false;
            })
            .addCase(getByGroupNameAPI.fulfilled, (state, action) => {
                state.getByGroupNameAPIListData = action.payload;
                state.getByGroupNameAPILoading = false;
                state.apiError = false;
            })
            .addCase(getByGroupNameAPI.rejected, (state, action) => {
                state.getByGroupNameAPILoading = false;
                state.getByGroupNameAPIListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //saveEntitlementAPI
            .addCase(saveEntitlementAPI.pending, (state, action) => {
                state.saveEntitlementAPILoading = true;
                state.apiError = false;
            })
            .addCase(saveEntitlementAPI.fulfilled, (state, action) => {
                state.saveEntitlementAPIData = action.payload;
                state.saveEntitlementAPILoading = false;
                state.apiError = false;
            })
            .addCase(saveEntitlementAPI.rejected, (state, action) => {
                state.saveEntitlementAPILoading = false;
                state.saveEntitlementAPIData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            .addCase(getSoftwareGroupDivisionCategory.pending, (state, action) => {
              state.getSoftwareGroupDivisionCategoryData = [],
              state.getSoftwareGroupDivisionCategoryDataLoading = true
              state.error = false;
            })
            .addCase(getSoftwareGroupDivisionCategory.fulfilled, (state, action) => {
              state.getSoftwareGroupDivisionCategoryData = action.payload,
              state.getSoftwareGroupDivisionCategoryDataLoading = false
              state.error = false;
            })
            .addCase(getSoftwareGroupDivisionCategory.rejected, (state, action) => {
              state.getSoftwareGroupDivisionCategoryDataLoading = false
              state.error = true;
            })
            //getInfoEntitleListAPI
            .addCase(getInfoEntitleListAPI.pending, (state, action) => {
              state.getInfoEntitleListData = [],
              state.getInfoEntitleListLoading = true
              state.error = false;
            })
            .addCase(getInfoEntitleListAPI.fulfilled, (state, action) => {
              state.getInfoEntitleListData = action.payload,
              state.getInfoEntitleListLoading = false
              state.error = false;
            })
            .addCase(getInfoEntitleListAPI.rejected, (state, action) => {
              state.getInfoEntitleListLoading = false
              state.error = true;
            })
            //getInfoAttestedListAPI
            .addCase(getInfoAttestedListAPI.pending, (state, action) => {
              state.getInfoAttestedListData = [],
              state.getInfoAttestedListLoading = true
              state.error = false;
            })
            .addCase(getInfoAttestedListAPI.fulfilled, (state, action) => {
              state.getInfoAttestedListData = action.payload,
              state.getInfoAttestedListLoading = false
              state.error = false;
            })
            .addCase(getInfoAttestedListAPI.rejected, (state, action) => {
              state.getInfoAttestedListLoading = false
              state.error = true;
            })
            //getInfoEntitleListConsumedLicensesInfo
            .addCase(getInfoEntitleListConsumedLicensesInfo.pending, (state, action) => {
                state.getInfoEntitleListConsumedLicensesInfoData = [],
                state.getInfoEntitleListConsumedLicensesInfoLoading = true
                state.error = false;
            })
            .addCase(getInfoEntitleListConsumedLicensesInfo.fulfilled, (state, action) => {
                state.getInfoEntitleListConsumedLicensesInfoData = action.payload,
                state.getInfoEntitleListConsumedLicensesInfoLoading = false
                state.error = false;
            })
            .addCase(getInfoEntitleListConsumedLicensesInfo.rejected, (state, action) => {
                state.getInfoEntitleListConsumedLicensesInfoLoading = false
                state.error = true;
            })
            //getInfoEntitleListConsumedLicensesInfoCount
            .addCase(getInfoEntitleListConsumedLicensesInfoCount.pending, (state, action) => {
                state.getInfoEntitleListConsumedLicensesInfoCountData = [],
                state.getInfoEntitleListConsumedLicensesInfoCountLoading = true
                state.error = false;
            })
            .addCase(getInfoEntitleListConsumedLicensesInfoCount.fulfilled, (state, action) => {
                state.getInfoEntitleListConsumedLicensesInfoCountData = action.payload,
                state.getInfoEntitleListConsumedLicensesInfoCountLoading = false
                state.error = false;
            })
            .addCase(getInfoEntitleListConsumedLicensesInfoCount.rejected, (state, action) => {
                state.getInfoEntitleListConsumedLicensesInfoCountLoading = false
                state.error = true;
            })
            //saveNumberOfLicenseConsumed
            .addCase(saveNumberOfLicenseConsumed.pending, (state, action) => {
                state.saveNumberOfLicenseConsumedData = [],
                state.saveNumberOfLicenseConsumedLoading = true
                state.error = false;
            })
            .addCase(saveNumberOfLicenseConsumed.fulfilled, (state, action) => {
                state.saveNumberOfLicenseConsumedData = action.payload,
                state.saveNumberOfLicenseConsumedLoading = false
                state.error = false;
            })
            .addCase(saveNumberOfLicenseConsumed.rejected, (state, action) => {
                state.saveNumberOfLicenseConsumedLoading = false
                state.error = true;
            })
            //getByAttestActionAPI
            .addCase(getByAttestActionAPI.pending, (state, action) => {
                state.getByAttestActionData = [],
                state.getByAttestActionLoading = true
                state.error = false;
            })
            .addCase(getByAttestActionAPI.fulfilled, (state, action) => {
                state.getByAttestActionData = action.payload,
                state.getByAttestActionLoading = false
                state.error = false;
            })
            .addCase(getByAttestActionAPI.rejected, (state, action) => {
                state.getByAttestActionLoading = false
                state.error = true;
            })
            //saveAttestationActionAPI
            .addCase(saveAttestationActionAPI.pending, (state, action) => {
                state.saveAttestationActionData = [],
                state.saveAttestationActionLoading = true
                state.error = false;
            })
            .addCase(saveAttestationActionAPI.fulfilled, (state, action) => {
                state.saveAttestationActionData = action.payload,
                state.saveAttestationActionLoading = false
                state.error = false;
            })
            .addCase(saveAttestationActionAPI.rejected, (state, action) => {
                state.saveAttestationActionLoading = false
                state.error = true;
            })
            //getByValidateActionAPI
            .addCase(getByValidateActionAPI.pending, (state, action) => {
                state.getByValidateActionData = [],
                state.getByValidateActionLoading = true
                state.error = false;
            })
            .addCase(getByValidateActionAPI.fulfilled, (state, action) => {
                state.getByValidateActionData = action.payload,
                state.getByValidateActionLoading = false
                state.error = false;
            })
            .addCase(getByValidateActionAPI.rejected, (state, action) => {
                state.getByValidateActionLoading = false
                state.error = true;
            })
            //getConfirmValidationAPI
            .addCase(getConfirmValidationAPI.pending, (state, action) => {
                state.getConfirmValidationData = [],
                state.getConfirmValidationLoading = true
                state.error = false;
            })
            .addCase(getConfirmValidationAPI.fulfilled, (state, action) => {
                state.getConfirmValidationData = action.payload,
                state.getConfirmValidationLoading = false
                state.error = false;
            })
            .addCase(getConfirmValidationAPI.rejected, (state, action) => {
                state.getConfirmValidationLoading = false
                state.error = true;
            })
            //getAttestationInfoCountAPI
            .addCase(getAttestationInfoCountAPI.pending, (state, action) => {
                state.getAttestationInfoCountData = [],
                state.getAttestationInfoCountLoading = true
                state.error = false;
            })
            .addCase(getAttestationInfoCountAPI.fulfilled, (state, action) => {
                state.getAttestationInfoCountData = action.payload,
                state.getAttestationInfoCountLoading = false
                state.error = false;
            })
            .addCase(getAttestationInfoCountAPI.rejected, (state, action) => {
                state.getAttestationInfoCountLoading = false
                state.error = true;
            })
            //getConsumedCountForValidation
            .addCase(getConsumedCountForValidation.pending, (state, action) => {
                state.getConsumedCountForValidationData = [],
                state.getConsumedCountForValidationLoading = true
                state.error = false;
            })
            .addCase(getConsumedCountForValidation.fulfilled, (state, action) => {
                state.getConsumedCountForValidationData = action.payload,
                state.getConsumedCountForValidationLoading = false
                state.error = false;
            })
            .addCase(getConsumedCountForValidation.rejected, (state, action) => {
                state.getConsumedCountForValidationLoading = false
                state.error = true;
            })
            //getSoftwareIncludesAPI
            .addCase(getSoftwareIncludesAPI.pending, (state, action) => {
                state.getSoftwareIncludesAPIData = [],
                state.getSoftwareIncludesAPILoading = true
                state.error = false;
            })
            .addCase(getSoftwareIncludesAPI.fulfilled, (state, action) => {
                state.getSoftwareIncludesAPIData = action.payload,
                state.getSoftwareIncludesAPILoading = false
                state.error = false;
            })
            .addCase(getSoftwareIncludesAPI.rejected, (state, action) => {
                state.getSoftwareIncludesAPILoading = false
                state.error = true;
            })
            //getGroupNameListByUserName
            .addCase(getGroupNameListByUserName.pending, (state, action) => {
                state.getGroupNameListByUserNameData = [],
                state.getGroupNameListByUserNameLoading = true
                state.error = false;
            })
            .addCase(getGroupNameListByUserName.fulfilled, (state, action) => {
                state.getGroupNameListByUserNameData = action.payload,
                state.getGroupNameListByUserNameLoading = false
                state.error = false;
            })
            .addCase(getGroupNameListByUserName.rejected, (state, action) => {
                state.getGroupNameListByUserNameLoading = false
                state.error = true;
            });

    },

});

export const { resetCostSliceData } = EntitlementSlice.actions;
export default EntitlementSlice.reducer;

