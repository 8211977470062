import _ from "lodash";

export const constants = {
  // BASE_URL: "http://ec2-34-210-211-227.us-west-2.compute.amazonaws.com:7771"
    // BASE_URL: ''
    BASE_URL:''
    // BASE_URL: 'http://localhost:7111'
}

export const DATE_FORMAT = "MM/DD/YYYY";

export const pageTitleMapper = {
  //Home
  "/home": {
    module: "",
    pageTitle: "Home",
  },

  "/home/userReview":{
    module: "",
    pageTitle: "Home > Application User Access Review",
  },
  "/home/BusinessInfoConfig": {
    module: "",
    pageTitle: "Home > Business Info",
  },
  "home/Devices": {
    module: "",
    pageTitle: "Home > Devices",
  },
  "home/Cloud Accounts": {
    module: "",
    pageTitle: "Home > Cloud Accounts",
  },
  "home/Identities(Users) ": {
    module: "",
    pageTitle: "Home > Identities(Users) ",
  },
    //Observability //title changed from Observability to DevOps Insights
    "/observability/flowMetrics": {
      module: "DevOps Insights > ",
      pageTitle: "Flow Metrics",
    },
    "/observability/doraMetrics": {
      module: "DevOps Insights > ",
      pageTitle: "Dora Metrics",
    },
    "/observability/peopleMetrics": {
      module: "DevOps Insights > ",
      pageTitle: "People Metrics",
    },
    "/observability/productivityMetrics": {
      module: "DevOps Insights > ",
      pageTitle: "Productivity Metrics",
    },
    "/observability/opsMetrics/incidentManagement": {
      module: "DevOps Insights > ",
      pageTitle: "Ops Metrics: Incident Management",
    },
    "/observability/opsMetrics/changeManagement": {
      module: "DevOps Insights > ",
      pageTitle: "Ops Metrics: Change Management",
    },
    //GNC
    "cloudCost/Insights": {
      module: "",
      pageTitle: "Cloud Cost > Insights",
    },

    "cloudCost/CostOverview": {
      module: "",
      pageTitle: "Cloud Cost > Cost Overview",
    },

    // "/gnc/compliance": {
    //   module: "Governance & Compliance - ",
    //   pageTitle: "Jenkins",
    // },
    // "/gnc/bitbucketTrend": {
    //   module: "Governance & Compliance - ",
    //   pageTitle: "Bitbucket",
    // },
    "/softwareLicenseManagement/Summary": {
      module: "License Management > ",
      pageTitle: "Summary",
      
    },
    "/softwareLicenseManagement/licenseManagement": {
      module: "License Management > ",
      pageTitle: "License Management",
      
    },
    "/softwareLicenseManagement/softwareGroup": {
      module: "Software License >",
      pageTitle: "Software Group",
    },
    "/gnc/PII": {
      module: "Governance & Compliance > ",
      pageTitle: "PII Data",
    },
    "/gnc/AdminActivity": {
      module: "Governance & Compliance > ",
      pageTitle: "Admin Activity",
    },
    "/gnc/CreateRiskAssessmentLanding": {
      module: "Governance & Compliance >",
      pageTitle: "Compliance Assessment",
    },
    "/gnc/AddRiskAssessment": {
      module: "Compliance  Assessment > ",
      pageTitle: "Create Compliance Assessment",
    },
    "/gnc/TopicName": {
      module: "Compliance  Assessment - ",
      pageTitle: "Topic Name",
    },
    "/gnc/TopicNameAssessment": {
      module: "Compliance  Assessment - ",
      pageTitle: "Topic Name",
    },
    "/gnc/SubtopicNameAssessment": {
      module: "Compliance  Assessment - ",
      pageTitle: "Sub Topic Name",
    },
    "/gnc/QuestionsAssessment": {
      module: "Compliance  Assessment - ",
      pageTitle: "Questions",
    },
    "/gnc/RiskAssesmentQuestion": {
      module: "Compliance  Assessment - ",
      pageTitle: "Compliance Questions",
    },
// Summary
    "/gnc/Compliance": {
      module: "Governance & Compliance > ",
      pageTitle: "Summary",
    },
    //Vulnerabilities
    "/gnc/Vulnerability": {
      module: "Governance & Compliance > ",
      pageTitle: "Vulnerability Management",
    },
    "/gnc/CISBenchmarkCompliance": {
      module: "Governance & Compliance > ",
      pageTitle: "CIS Benchmark Compliance",
    },
    "/gnc/cisoInsights": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    //compliance
    "/gnc/compliance/trmgCompliance": {
      module: "Governance & Compliance > ",
      pageTitle: "TRMG Compliance",
    },
    "/gnc/compliance/circular09Compliance": {
      module: "Governance & Compliance > ",
      pageTitle: "Circular 09 Compliance",
    },
    "/gnc/compliance/securityHub/securityHubFindingsLandings": {
      module: "Governance & Compliance > ",
      pageTitle: "Security Hub Findings",
    },
    "/gnc/compliance/CloudScapeFindings": {
      module: "Governance & Compliance > ",
      pageTitle: "CloudScape Findings",
    },
    "/gnc/compliance/identityComplianceManagement": {
      module: "Governance & Compliance > ",
      pageTitle: "Identity Compliance Management",
    },
    "/gnc/compliance/identityComplianceManagementNormal": {
      module: "Governance & Compliance - ",
      pageTitle: "Identity Compliance Management",
    },
    "/gnc/compliance/identityComplianceManagementCloud": {
      module: "Governance & Compliance - ",
      pageTitle: "Identity Compliance Management",
    },
    
    "/gnc/PCIDSSCompliance": {
      module: "Governance & Compliance > ",
      pageTitle: "PCI-DSS Compliance",
    },
    "/gnc/CISCompliance": {
      module: "Governance & Compliance > ",
      pageTitle: "CIS Compliance",
    },
    "/gnc/Compliance/Summary": {
      module: "Governance & Compliance > ",
      pageTitle: "Summary",
    },
    "/gnc/Compliance/UserAccessManagement": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Dashboard",
    },
    // "/gnc/UserAccessReview": {
    //   module: "Governance & Compliance > ",
    //   pageTitle: "User Access Review",
    // },
    //CISO Dashboard
    "/gnc/CISOInsights": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    "/gnc/securityHubFindings": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    "/gnc/CISOInsights/CloudScapeFindings": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    "/gnc/trmgCompliance": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    "/gnc/circular09Compliance": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    "/gnc/CISBenchmarkCompliance": {
      module: "Governance & Compliance > ",
      pageTitle: "CISO Insights",
    },
    "/gnc/userAccessReview": {
      module: "Governance & Compliance > CISO Insights > ",
      pageTitle: "User Access Review",
    },
    
    //Efficiency
    "/efficiency/onboardingtools": {
      module: "Efficiency > ",
      pageTitle: "Onboard Tools",
    },
    "/efficiency/accessManagement": {
      module: "Efficiency > ",
      pageTitle: "Access Management",
    },
    "/efficiency/myRequest": {
      module: "Efficiency > ",
      pageTitle: "My Request",
    },
    "/efficiency/myApproval": {
      module: "Efficiency > ",
      pageTitle: "My Approval",
    },
    "/efficiency/auditLog": {
      module: "Efficiency > ",
      pageTitle: "Audit Log",
    },
    "/efficiency/project": {
      module: "Efficiency > App Config > ",
      pageTitle: "Project",
    },
    "/efficiency/Devices": {
      module: "Efficiency > App Config >",
      pageTitle: "Device Configuration",
    },
    // "/efficiency/users": {
    //   module: "Efficiency > App Config >",
    //   pageTitle: "Users",
    // },
    "/efficiency/accountids": {
      module: "Efficiency > App Config >",
      pageTitle: "Cloud Accounts",
    },
    // '/efficiency/onboardingtools':{
    //   module: "Efficiency > App Config >",
    //   pageTitle: "Onboarding Tools",
    // },
    //Configuration
    "/configuration/settings/tools": {
      module: "Configuration > Settings >",
      pageTitle: "Tools",
    },
    "/configuration/settings/tools/bambooRole": {
      module: "Configuration > ",
      pageTitle: "Bamboo Roles",
    },
    "/configuration/settings/tools/bitbucketRole": {
      module: "Configuration > ",
      pageTitle: "Bitbucket Roles",
    },
    "/configuration/settings/tools/jenkinsRole": {
      module: "Configuration > ",
      pageTitle: "Jenkins Roles",
    },
    "/configuration/settings/tools/nexusRole": {
      module: "Configuration > ",
      pageTitle: "Nexus Roles",
    },
    "/configuration/settings/tools/jfrogRole": {
      module: "Configuration > ",
      pageTitle: "JFrog Roles",
    },
    "/configuration/settings/tools/jiracloudRole": {
      module: "Configuration > ",
      pageTitle: "Jira Cloud Roles",
    },
    "/configuration/settings/tools/jiraRole": {
      module: "Configuration > ",
      pageTitle: "Jira Roles",
    },
    "/configuration/settings/tools/confluencecloudRole": {
      module: "Configuration > ",
      pageTitle: "Confluence Cloud Roles",
    },
    "/configuration/settings/tools/sonarqubeRole": {
      module: "Configuration > ",
      pageTitle: "SonarQube Roles",
    },
    "/configuration/settings/groups": {
      module: "Configuration > Settings >",
      pageTitle: "Groups",
    },
    "/configuration/settings/PIIDataConfiguration": {
      module: "Configuration > Settings >",
      pageTitle: "PII Data Configuration",
    },
    "/configuration/settings/Schedulers": {
      module: "Configuration > Settings >",
      pageTitle: "Scheduler",
    },
    // "/configuration/settings/DataImportScheduler": {
    //   module: "Configuration > Settings >",
    //   pageTitle: "Data Import",
    // },
    "/configuration/administration/directoryConfiguration": {
      module: "Configuration > Administration >",
      pageTitle: "Directory Configuration",
    },
    "/configuration/administration/smtpConfig": {
      module: "Configuration > Administration >",
      pageTitle: "SMTP Configuration",
    },
    "/configuration/administration/licenseConfig": {
      module: "Configuration > Administration >",
      pageTitle: "License Configuration",
    },
    "/configuration/administration/userManagement": {
      module: "Configuration > Administration >",
      pageTitle: "User Management",
    },
    "/configuration/administration/userManagement/rolemapping": {
      module: "Configuration > Administration > User Management >",
      pageTitle: "Role Mapping",
    },
    "/configuration/administration/loggingConfig": {
      module: "Configuration > Administration >",
      pageTitle: "Logging Configuration",
    },
    "/configuration/cmdb/BusinessInfoConfig": {
      module: "Configuration > CMDB >",
      pageTitle: "Business Info",
    },
    "/configuration/cmdb/BusinessInfoConfig/roleAllocation": {
      module: "Configuration > CMDB >",
      pageTitle: "Role Allocation",
    },
    
    // "/configuration/cmdb/masterlist/UserInfoConfig": {
    //   module: "Configuration > CMDB >",
    //   pageTitle: "Users",
    // },

    "/configuration/cmdb/masterlist/Devices": {
      module: "Configuration > CMDB >",
      pageTitle: "Devices ",
    },
    "/configuration/cmdb/DeviceConfig/:id": {
      module: "Configuration > CMDB >",
      pageTitle: 'Devices/${id}',
    },
    "/configuration/cmdb": {
      module: "Configuration >",
      pageTitle: " CMDB",
    },
    "/configuration/cmdb/masterlist/cloudaccount": {
      module: "Configuration > CMDB >",
      pageTitle: "Cloud Accounts",
    },
    "/configuration/cmdb/riskAssessment": {
      module: "Configuration > CMDB >",
      pageTitle: "Risk Assessment",
    },
    "/configuration/cmdb/Devices/licenseconfigpage": {
      module: "Configuration > CMDB > Devices >",
      pageTitle: "OS License Config",
    },
    "/configuration/cmdb/Devices/newprovisionpage": {
      module: "Configuration > CMDB > Devices >",
      pageTitle: "Infra Provisioning",
    },
    "/configuration/cmdb/users": {
      module: "Configuration > CMDB > ",
      pageTitle: "Users",
    },
    "/configuration/cmdb/accountids": {
      module: "Configuration > CMDB > ",
      pageTitle: "Cloud Accounts",
    },
    "/efficiency/ApplicationDeatails-APC": {
      // module: "Configuration -",
      pageTitle: "Application Deatails - APC",
    },
    "/configuration/cmdb/applicationConfiguration": {
      module: "CMDB > ",
      pageTitle: "Application Configuration",
    },

    //License Management
    "/gnc/LicenseManagement/LincenseLanding.js": {
      module: "CMDB > ",
      pageTitle: "License Management",
    },
  };

export const getPageTitle = (pathName, routes) => {
  _.flatMapDeep(routes);
  const pathNameString = pathName.split("/");
  let returnString = "";
  pathNameString.map((name, index) => {
    name = _.capitalize(decodeURIComponent(name));
    if(name) {
      index < pathNameString.length - 1 ?  returnString += `${name} > ` : returnString += name;
    }
  });
  return returnString;
}

export const pathNames = {
  "cmdb": "CMDB",
  "Businessinfoconfig": "Business Info Config",
  "gnc": "Governance & Compliance",
  "Vulnerability": "Vulnerability Management",
  "compliance": "",
  "Compliance": "",
  "trmgCompliance": "TRMG Compliance",
  "circular09Compliance": "Circular 09 Compliance",
  "securityHubFindingsLandings": "Security Hub Findings",
  "LincenseLanding": "License Management",
  "CloudScapeFindings": "CloudScape Findings",
  "securityHub": "",
  "identityComplianceManagement": "Identity Compliance Management",
  "CISBenchmarkCompliance": "CIS Benchmark Compliance",
  "PII": "PII Data",
  "PCIDSSCompliance": "PCI-DSS Compliance",
  "CISOInsights": "CISO Insights",
  "trmgCompliance": "TRMG Compliance",
  "circular09Compliance": "Circular 09 Compliance",
  "securityHubFindings": "Security Hub Findings",
  // "CisoUserAccess": "User Access",
  "userAccessReview": "User Access Review",
  "CISBenchmarkCompliance": "CIS Benchmark Compliance",
  "CloudCostInsights": "Cloud Cost > Findings",
  "licenseManagement": "License Management",
  "LicenseEntitlement": "License Management",
  "AdminActivity": "Admin Activity",
  "efficiency": "Efficiency",
  "appConfig": "App Config",
  "accessManagement": "Access Management",
  "myRequest": "My Request",
  "myApproval": "My Approval",
  "auditLog": "Audit Log",
  "configuration": "Configuration",
  "settings": "Settings",
  "tools": "Tools",
  "groups": "Groups",
  "PIIDataConfiguration": "PII Data Configuration",
  "EmailScheduleConfiguration": "Email Schedule Configuration",
  "DataImportScheduler":"Data Import",
  "administration": "Administration",
  "directoryConfiguration": "Directory Configuration",
  "smtpConfig": "SMTP Configuration",
  "licenseConfig": "License Configuration",
  "userManagement": "User Management",
  "loggingConfig": "Logging Configuration",
  "BusinessInfoConfig": "Business Info",
  "roleAllocation": "Role Allocation",
  "rolemapping": "Role Mapping",
  "UserInfoConfig": " Master List - Users",
  "SoftwareList": "Software List",
  "cloudaccounttable": "",
  "cloudaccount": " Master List - Cloud Accounts",
  "riskAssessment": "Risk Assessment",
  "licenseconfigpage": "OS License",
  "observability": "DevOps Insights",
  "flowMetrics": "Flow Metrics",
  "doraMetrics": "Dora Metrics",
  "peopleMetrics": "People Metrics",
  "productivityMetrics": "Productivity Metrics",
  "devopsMetrics": "Devops Metrics",
  "opsMetrics": "Ops Metrics",
  "incidentManagement": "Incident Management",
  "changeManagement": "Change Management",
  "userReview": "Application User Access Review",
  "home": "Home",
  "DeviceConfig": "Devices",
  "users": "Users",
  "accountids": "Cloud Accounts",
  "Devices": "Master List - Devices",
  "applicationConfiguration": "Application Configuration",
  "project": "Projects",
  "Summary": "Summary",
  "sonarqubeRole": "SonarQube Roles",
  "confluencecloudRole":"Confluence Cloud Roles",
  "jiraRole":"Jira Roles",
  "jiracloudRole": "Jira Cloud Roles",
  "jfrogRole":"JFrog Roles",
  "nexusRole":"Nexus Roles",
  "jenkinsRole":"Jenkins Roles",
  "bitbucketRole":"Bitbucket Roles",
  "bambooRole":"Bamboo Roles",
  "onboardingtools" : "Onboard Tools",
  "CloudCost":"Cloud Cost",
  "cloudCost":"Cloud Cost",
  "Insights":"Insights",
  "CostOverview":"Cost Overview",
  "softwareGroup" : "Software Group",
  "softwareLicenseManagement" : "Software License Management",
  "masterlist" : ""
}
  

  export const userRestrictedAccess = [
   "/efficiency/onboardingtools",
   "/onboardingtools",
   "/efficiency/myApproval",
   "/configuration/settings/tools",
   "/configuration/settings/groups",
   "/configuration/settings/PIIDataConfiguration",
   "/configuration/administration/directoryConfiguration",
   "/configuration/administration/smtpConfig",
   "/configuration/administration/licenseConfig",
   "/configuration/administration/userManagement",
   "/configuration/administration/loggingConfig"
  ]